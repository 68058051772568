import { ref } from "@vue/composition-api";
import store from "@/store";

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: "",
    priceRangeDefined: "all",
    priceRange: [0, 100],
    categories: [],
    brands: [],
    ratings: null,
    page: 1,
    perPage: 9,
  });

  // Sorting
  const sortBy = ref({ text: "Todos", value: "featured" });
  const sortByOptions = [
    { text: "Todos", value: "all" },
    { text: "Backlog", value: "featured" },
    { text: "Em Execução", value: "price-asc" },
    { text: "Finalizado", value: "price-desc" },
  ];

  return {
    // Filter
    filters,

    // Sort
    sortBy,
    sortByOptions,
  };
};

export const useShopUi = () => {
  const itemView = "grid-view";
  const itemViewOptions = [
    { icon: "GridIcon", value: "grid-view" },
    { icon: "ListIcon", value: "my-3" },
  ];

  // Pagination count <= required by pagination component
  const totalProducts = ref(null);

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  };
};

export const useShopRemoteData = () => {
  const products = ref([]);
  const fetchProducts = (...args) =>
    store.dispatch("app-ecommerce/fetchProducts", ...args);

  return {
    products,
    fetchProducts,
  };
};
