<template>
  <div>
    <task-card-add-new
      :is-add-new-task-sidebar-active.sync="isAddNewTaskSidebarActive"
      :project-options="projectOptions"
      :task-status-options="taskStatusOptions"
      :task-type-options="taskTypeOptions"
      :task-priority-options="taskPriorityOptions"
    />
    <b-row>
      <!-- Search -->
      <b-col cols="12" md="12">
        <div
          v-bind:class="{
            'align-items-center justify-content-end': isActiveCards,
            'd-flex align-items-center justify-content-end': !isActiveCards,
          }"
        >
          <b-input-group class="mr-1">
            <b-form-input
              v-model="searchQuery"
              @keyup.enter="searchWord()"
              :placeholder="$t('message.bookmark.search')"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="searchWord()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <br v-if="isActive" />
          <div
            v-bind:class="{
              'd-flex align-items-center': !isActiveCards,
              'd-flex align-items-center justify-content-end': isActiveCards,
            }"
          >
            <div>
              <div class="ecommerce-header-items">
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                >
                  <template slot="button-content">
                     {{ $t("message.todo.status") }}
                    <feather-icon icon="FilterIcon" />
                  </template>
                  <b-dropdown-item @click="setFilter('status', 'all')">
                    {{ $t("message.projects.all") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="setFilter('status', 'Backlog')">
                    {{ $t("message.projects.outstanding") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="setFilter('status', 'Em execução')">
                    {{ $t("message.projects.running") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="setFilter('status', 'Finalizado')">
                    {{ $t("message.projects.finalized") }}
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  <template slot="button-content">
                     {{ $t("message.todo.priority") }}
                    <feather-icon icon="FilterIcon" />
                  </template>
                  <b-dropdown-item @click="setFilter('priority', 'all')">
                    {{ $t("message.projects.all") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="setFilter('priority', 'Urgente')">
                    {{ $t("message.todo.urgent") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="setFilter('priority', 'Alta')">
                    {{ $t("message.todo.high") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="setFilter('priority', 'Normal')">
                    {{ $t("message.todo.normal") }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <b-form-radio-group
              v-model="itemView"
              class="ml-1 mr-1 list item-view-radio-group"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon :icon="option.icon" />
              </b-form-radio>
            </b-form-radio-group>

            <b-button
              variant="primary"
              @click="isAddNewTaskSidebarActive = true"
              v-if="restrictions('button_new_task')"
            >
              <span class="text-nowrap">{{
                $t("message.buttons.newTask")
              }}</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <section v-if="taskList.length === 0 && !isBusy">
      <div
        class="text-center"
        style="color: #c0c0c0; margin-top: 17.2rem; margin-bottom: 17.2rem"
      >
        <strong> {{ $t("message.registerNotFound") }}</strong>
      </div>
    </section>

    <section v-if="isBusy">
      <div
        class="text-center"
        style="color: #c0c0c0; margin-top: 17.2rem; margin-bottom: 17.2rem"
      >
        <b-spinner class="align-middle"></b-spinner>
        <strong> {{ $t("message.load") }}... </strong>
      </div>
    </section>

    <!-- Tasks -->
    <section v-else class="wishlist-items" :class="itemView">
      <b-card
        v-for="task in taskList"
        :key="task.id"
        class="ecommerce-card"
        no-body
      >
        <!-- Task Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body d-flex"
              style="gap: 0.5rem"
              :to="{ name: 'apps-task-view', params: { id: task.id } }"
            >
              <h3 :id="'task-name-' + task.id" style="max-width: 245px">{{ task.subject }}</h3>
              <b-tooltip
                v-if="task.subject.length > 22"
                custom-class="long-tooltip"
                :target="'task-name-' + task.id"
                triggers="hover"
                no-fade
              >
                {{ task.subject }}
              </b-tooltip>
               <IconLinkInternal size="12"/>
              <!-- <feather-icon icon="ExternalLinkIcon" size="12"/> -->
            </b-link>
          </h6>
          <b-card-text class="item-description">
            <span v-html="task.description.substring(0, 50) + '..'"></span>
          </b-card-text>

          <b-badge :variant="badgeColor(task.status)">
            {{ taskPriorityLocale(task.status) }}
          </b-badge>

          <b-badge
            v-if="task.projectName !== 'deleted project'"
            v-bind:class="{ 'mx-1': isActive, 'mx-1': !isActive }"
            :to="{
              name: 'apps-projects-details',
              params: { id: task.projectId },
            }"
          >
            <feather-icon icon="LinkIcon" class="mr-25" />
            <span> {{ limitCharacters(task.projectName) }}</span>
          </b-badge>

          <br />
          <br />
          <div>
            <div>
              <ul class="unstyled-list list-inline" />
            </div>
            <div>
              <h6 style="font-weight: normal">
                {{ $t("message.date.start") }}
                <b>{{ localeDate(task.startDate) }}</b>
                <br v-if="isActive" />
                {{ $t("message.date.end") }}
                <b v-if="lateTask(task)" style="color: red">{{
                  localeDate(task.endDate)
                }}</b>
                <b v-else>{{ localeDate(task.endDate) }}</b>
              </h6>
            </div>
          </div>

          <b-progress
            variant="primary"
            class="progress-bar-secondary mt-2 ml-0"
          >
            <b-progress-bar
              :value="task.percentage"
              :label="`${((task.percentage / 100) * 100).toFixed(0)}%`"
            ></b-progress-bar>
          </b-progress>
        </b-card-body>
      </b-card>
    </section>

    <!-- Pagination -->
    <section v-if="showPagination">
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRegisters"
            :per-page="paramData.cardSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
            @input="eventPage(currentPage)"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BSpinner,
  BDropdownItem,
  BBadge,
  BProgress,
  BProgressBar,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
//import usetasksList from "../task-list/useTasksList";
import { useShopUi } from "./useTaskCards";
import TaskCardAddNew from "./TaskCardAddNew.vue";
import { ref } from "@vue/composition-api";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import CharacterLimit from "@core/utils/characterLimit";
import store from "@/store";
import IconLinkInternal from "@core/components/iconLink/IconLinkInternal.vue"

export default {
  directives: {
    Ripple,
  },
  components: {
    TaskCardAddNew,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BProgress,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BSpinner,
    BProgressBar,
    BTooltip,
    IconLinkInternal
  },

  data() {
    return {
      taskList: [],
      searchQuery: "",
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      size: 16,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,

      //Select
      projectOptions: [],
      taskStatusOptions: [],
      taskTypeOptions: [],
      taskPriorityOptions: [],

      //Param
      paramData: store.state.user.paramData,

      //CurrentUser
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
      isActiveCards: true,
      limitCharactersValue: 20,

      filter: {
        status: "all",
        priority: "all",
      },
    };
  },

  async created() {
    await this.getDataTasks(1);
    this.onResize();

    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });

    var optionsList = [];
    await axios
      .get(`${URL_API}task_status`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);
          this.taskStatusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}task_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.taskTypeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });

    var priorityList = [];
    await axios
      .get(`${URL_API}task_priority`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].priority;
          priorityList.push(element);
          this.taskPriorityOptions.push({
            label: priorityList[i],
            value: priorityList[i],
          });
        }
      });
  },

  mounted() {
    this.$root.$on("newListTask", async (newListTask) => {
      this.isBusy = true;

      await this.getDataTasks(1)
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  setup() {
    const isAddNewTaskSidebarActive = ref(false);

    const { itemView, itemViewOptions } = useShopUi();

    return {
      itemView,
      itemViewOptions,
      isAddNewTaskSidebarActive,
    };
  },

  methods: {
    async setFilter(type, value) {
      this.filter[type] = value;
      await this.getDataTasks(1)
    },
    async getDataTasks(currentPage) {
      try {
        this.isBusy = true;

        const queryParams = new URLSearchParams();
        const filter = this.filter
          for (const key in filter) {
              if (filter) {
                  queryParams.append(key, filter[key]);
              }
          }
  
          if (this.restrictions("task-list-all")) {   
            await axios
              .get(
                `${URL_API}task/list-dto?page=${currentPage - 1}&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}&${queryParams.toString()}`,
                {
                  headers: getHeader(this.userData),
                }
              )
              .then((response) => {
                this.taskList = response.data.content;
                this.totalRegisters = response.data.totalElements;
                this.perPage = response.data.numberOfElements;
              });
          } else {
            axios
            .get(
              `${URL_API}task/${this.userData.id}/list-dto?page=${currentPage - 1}&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}&${queryParams.toString()}`,
              {
                headers: getHeader(this.userData),
              }
            )
            .then((response) => {
              this.taskList = response.data.content;
              this.totalRegisters = response.data.totalElements;
              this.perPage = response.data.numberOfElements
            });
          }
      } catch (e) {
        console.error("Error getting data tasks", e)
      } finally {
        this.currentPage = currentPage
        this.isBusy = false;
      }
    },
    restrictions(value) {
      return AccessControl(value);
    },

    limitCharacters(value) {
      return CharacterLimit(value, this.limitCharactersValue);
    },

    taskPriorityLocale(value) {
      if (value === "Em execução") {
        return this.getColLabel("inProgress");
      } else if (value === "Rejeitado") {
        return this.getColLabel("rejected");
      } else {
        return this.getColLabel("done");
      }
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1680) {
        this.isActive = false;
        this.limitCharactersValue = 20;
      } else {
        this.isActive = true;
        this.limitCharactersValue = 15;
      }

      this.isActiveCards = ListWidth(this.windowWidth);
    },

    getColLabel(colName) {
      return this.$i18n.t(colName);
    },

    async eventPage(currentPage) {
      this.isBusy = true;
      await this.getDataTasks(currentPage);
    },

    async getTaskFilter() {
      if (this.restrictions("task-list-all")) {
        this.isBusy = true;
        this.showPagination = false;
        await axios
          .get(`${URL_API}task/search?searchTerm=${this.searchQuery}`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            this.taskList = [];
            if (response.data != []) {
              for (var i = 0; i < response.data.length; i++) {
                this.taskList.push(response.data[i]);
              }
            }
            this.isBusy = false;
            this.showPagination = false;
          });
      } else {
        this.isBusy = true;
        this.showPagination = false;
        await axios
          .get(
            `${URL_API}task/${this.userData.id}/search?searchTerm=${this.searchQuery}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.taskList = [];
            if (response.data != []) {
              for (var i = 0; i < response.data.length; i++) {
                this.taskList.push(response.data[i]);
              }
            }
            this.isBusy = false;
            this.showPagination = false;
          });
      }
    },

    async searchWord() {
      if (this.searchQuery) {
        this.getTaskFilter();
      } else {
        await this.getDataTasks(1)
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    badgeColor(value) {
      if (value === "Backlog") {
        return "secondary";
      }
      if (value === "Em execução") {
        return "primary";
      }
      if (value === "Finalizado") {
        return "success";
      }
      if (value === "Rejeitado") {
        return "danger";
      }
      return "primary";
    },

    lateTask(param) {
      var dateToCompare = moment(param.endDate);
      var today = moment(new Date());

      if (param.percentage > 99) {
        return false;
      }

      if (dateToCompare.startOf("day").isSameOrAfter(today.startOf("day"))) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.long-tooltip::v-deep .tooltip-inner {
  max-width: 45rem;
}
</style>
