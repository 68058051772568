<template>
  <b-sidebar
    id="add-new-task-sidebar"
    :visible="isAddNewTaskSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-task-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.tasks.createNewTask") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewtask())"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="taskData.subject"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider name="descrição" rules="required">
            <b-form-group label-for="description">
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 82px; height: 300px"
                :options="editorOption"
                v-model="taskData.description"
              />
            </b-form-group>
          </validation-provider>

          <b-form-group label-for="wildcard">
            <template v-slot:label> {{ $t("uploadFile") }} </template>
            <b-form-file
              id="wildcard"
              ref="file-input"
              class="mr-1"
              v-model="file"
              browse-text="Procurar"
              placeholder="Nenhum arquivo selecionado"
              drop-placeholder="Arraste arquivo aqui"
              :accept="fileFormats"
            >
            </b-form-file>
          </b-form-group>

          <!-- Start Date -->
          <validation-provider
            #default="validationContext"
            name="data inicial"
            rules="required"
          >
            <b-form-group label-for="startDate">
              <template v-slot:label>
                {{ $t("message.date.start") }}
              </template>
              <flat-pickr
                v-model="taskData.startDate"
                class="form-control"
                :config="flatConfig"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.dateStart") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- End Date -->
          <validation-provider
            #default="validationContext"
            name="data final prevista"
            rules="required"
          >
            <b-form-group label-for="endDate">
              <template v-slot:label>
                {{ $t("message.date.end") }}
              </template>
              <flat-pickr
                v-model="taskData.endDate"
                class="form-control"
                :config="flatConfig"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.dateEnd") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Estimated Time -->
          <validation-provider
            #default="validationContext"
            name="tempo estimado"
            rules="required"
          >
            <b-form-group label-for="estimatedTime">
              <template v-slot:label>
                {{ $t("message.projects.estimatedTime") }}
              </template>
              <number-input
                v-model="taskDataEstimateTime"
                center
                controls
                :min="0"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Project -->
          <validation-provider
            #default="validationContext"
            name="projeto"
            rules="required"
          >
            <b-form-group
              label-for="projectId"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.projects") }}
              </template>
              <v-select
                v-model="taskData.projectId"
                :options="projectOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-project"
                @input="projectMembersList(taskData.projectId)"
              />
              <b-form-invalid-feedback> </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Members -->
          <validation-provider #default="validationContext" name="membros">
            <b-form-group
              label-for="userId"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.team.members") }}
              </template>
              <v-select
                v-if="taskData.projectId"
                multiple
                v-model="taskData.members"
                :options="userOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-user"
              />
              <b-form-input v-else readonly />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sprints -->
          <validation-provider #default="validationContext" name="Sprints">
            <b-form-group
              label-for="sprintId"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("sprint") }}
              </template>
              <v-select
                v-if="taskData.projectId && sprintOptions.length > 0"
                v-model="taskSprintSelected"
                :options="sprintOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-user"
              />
              <b-form-input v-else readonly />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="tipo"
            rules="required"
          >
            <b-form-group
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tasks.type") }}
              </template>
              <v-select
                v-model="taskData.type"
                :options="taskTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-status"
              />
            </b-form-group>
          </validation-provider>

          <!-- Pirority -->
          <validation-provider
            #default="validationContext"
            name="prioridade"
            rules="required"
          >
            <b-form-group
              label-for="priority"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tasks.priority") }}
              </template>
              <v-select
                v-model="taskData.priority"
                :options="taskPriorityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-status"
              />
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import Vue from "vue";
import Email from "@/@core/utils/email/email.js";
import VueNumberInput from "@chenfengyuan/vue-number-input";
Vue.use(VueNumberInput);
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import getHeaderFile from "@core/utils/authFile";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    BFormFile,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTaskSidebarActive",
    event: "update:is-add-new-task-sidebar-active",
  },
  props: {
    isAddNewTaskSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectOptions: {
      type: Array,
      required: true,
    },
    taskStatusOptions: {
      type: Array,
      required: true,
    },
    taskTypeOptions: {
      type: Array,
      required: true,
    },
    taskPriorityOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      flatConfig: {
        dateFormat: "d/m/Y",
      },

      userOptions: [],
      sprintOptions: [],

      taskDataEstimateTime: 0,

      //Param
      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      //CurrentUser
      userData: store.state.user.userData,

      disabledButton: false,

      taskSprintSelected: "",

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs["file-input"].reset();
        }
      }
    },
  },

  async created() {
    this.loadFileFormats();
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  methods: {
    sendEmail(type, obj) {
      return Email(type, obj);
    },

    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    addFile(taskId) {
      if (this.file != null) {
        let formData = new FormData();
        formData.append("file", this.file);

        axios
          .post(`${URL_API}file/task/${this.userData.id}/${taskId}`, formData, {
            headers: getHeaderFile(this.userData),
          })
          .then((response) => {
            if (!response.data.id && response.data != "") {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("noDataAvailable"),
                  icon: "XIcon",
                  variant: "danger",
                  text: this.$i18n.t("noDataAvailableDesc"),
                },
              });
            } else if (response.data === "") {
              this.file = null;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("Error"),
                  icon: "XIcon",
                  variant: "warning",
                  text: this.$i18n.t("offline"),
                },
              });
            }
          });
      }
    },

    async projectMembersList(projectId) {
      var projectObj = [];
      await axios({
        method: "get",
        url: `${URL_API}project/${projectId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        projectObj = response.data;
      });

      var teamObj = [];
      for (var i = 0; i < projectObj.teams.length; i++) {
        await axios({
          method: "get",
          url: `${URL_API}team/${projectObj.teams[i]}`,
          headers: getHeader(this.userData),
        }).then((response) => {
          for (var j = 0; j < response.data.members.length; j++) {
            teamObj.push(response.data.members[j]);
          }
        });
      }

      // Remove duplicated items
      teamObj = teamObj.filter(function (value, index, array) {
        return array.indexOf(value) === index;
      });

      this.userOptions = [];
      this.taskData.members = null;
      for (var i = 0; i < teamObj.length; i++) {
        await axios({
          method: "get",
          url: `${URL_API}user/userEmailById/${teamObj[i]}`,
          headers: getHeader(this.userData),
        }).then((response) => {
          this.userOptions.push({
            label: response.data.email,
            value: response.data.id,
          });
        });
      }

      //Sprint select
      this.sprintOptions = [];
      await axios({
        method: "get",
        url: `${URL_API}sprint/projectId/${projectId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        for (var j = 0; j < response.data.length; j++) {
          if (response.data[j].status != "Finished") {
            this.sprintOptions.push({
              label: response.data[j].subject,
              value: response.data[j].id,
            });
          }
        }
      });
    },

    async updateSprint(id) {
      var sprintData = [];
      var sprintDataFinal = [];
      await axios({
        method: "get",
        url: `${URL_API}sprint/${this.taskSprintSelected}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        sprintData = response.data;
      });

      if (sprintData.tasks) {
        sprintData.tasks.push(id);
        sprintDataFinal = sprintData;
      } else {
        var listOfIds = { tasks: [id] };
        sprintDataFinal = { ...sprintData, ...listOfIds };
      }

      axios({
        method: "put",
        url: `${URL_API}sprint/${this.taskSprintSelected}`,
        headers: getHeader(this.userData),
        data: sprintDataFinal,
      });

      //Limpar
      this.taskSprintSelected = "";
    },

    submitNewtask() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          this.taskData.startDate = date(
            this.$i18n.locale,
            this.taskData.startDate
          );
          this.taskData.endDate = date(
            this.$i18n.locale,
            this.taskData.endDate
          );

          this.taskData.percentage = 0;

          if (!this.taskData.members || this.taskData.members.length === 0) {
            this.taskData.status = "Backlog";
          } else {
            this.taskData.status = "Em execução";
          }

          if (!this.taskData.members) {
            this.taskData.members = [];
          }

          this.taskData.estimateTime = this.taskDataEstimateTime;

          var label = "";

          for (var i = 0; i < this.projectOptions.length; i++) {
            if (this.projectOptions[i].value === this.taskData.projectId) {
              label = this.projectOptions[i].label;
            }
          }

          var copy = this.taskData;
          var emailObj = { projectName: label };

          let merged = { ...copy, ...emailObj };

          this.sendEmail("task", merged);

          axios({
            method: "post",
            url: `${URL_API}task`,
            headers: getHeader(this.userData),
            data: this.taskData,
          })
            .then((response) => {
              if (this.taskSprintSelected != "") {
                this.updateSprint(response.data.id);
              }
              this.addFile(response.data.id);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Tarefa ${this.taskData.subject} criada com sucesso`,
                },
              });
              this.file = null;
            })
            .then(() => {
              this.$root.$emit("newListTask", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },
  },

  setup(props, { emit }) {
    const blanktaskData = {};

    const taskData = ref(JSON.parse(JSON.stringify(blanktaskData)));
    const resettaskData = () => {
      taskData.value = JSON.parse(JSON.stringify(blanktaskData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-task-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettaskData);

    return {
      taskData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-task-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
